<template>
	<div class="page-content no-padding">
        <div class="flex flex-column align-items-center justify-content-center h-full">
            <div class="py-4">
                <p class="font-bold mb-4">Puedes cambiar los datos de tu perfil modificando los siguientes campos.</p>
                <label for="name" class="block text-900 font-medium mb-2">Nombre</label>
                <InputText v-model="v$.name.$model" id="name" type="text" class="w-full mb-4" :class="{'p-invalid': v$.name.$error}" required />
                <div v-for="error of v$.name.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="email" class="block text-900 font-medium mb-2">Email</label>
                <InputText v-model.trim="v$.email.$model" id="email" type="email" class="w-full mb-4" :class="{'p-invalid': v$.email.$error}" required />
                <div v-for="error of v$.email.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="dwelling" class="block text-900 font-medium mb-2">Vivienda</label>
                <InputText v-model.trim="v$.dwelling.$model" id="dwelling" type="text" class="w-full mb-4" :class="{'p-invalid': v$.dwelling.$error}" required />
                <div v-for="error of v$.dwelling.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="phone" class="block text-900 font-medium mb-2">Teléfono</label>
                <InputText v-model.trim="v$.phone.$model" id="phone" class="w-full mb-4" :class="{'p-invalid': v$.phone.$error}" required />
                <div v-for="error of v$.phone.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="password" class="block text-900 font-medium mb-2">Contraseña (déjalo vacío para mantener la actual) <i class="pi pi-info-circle ml-1" v-tooltip="'Mínimo 8 caracteres, 1 minúscula, 1 mayúscula, 1 número, 1 símbolo'" /></label>
                <Password v-model="v$.password.$model" id="password" :toggleMask="true" class="mb-4" required />
                <div v-for="error of v$.password.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <label for="password2" class="block text-900 font-medium mb-2">Repite la contraseña</label>
                <Password v-model="v$.password2.$model" id="password2" :toggleMask="true" class="mb-4" required />
                <div v-for="error of v$.password2.$errors" :key="error.$uid" class="mb-4">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>

                <div class="flex flex-row items-center mb-4">
                    <Checkbox v-model="communications" :binary="true" id="communications" class="mr-2" />
                    <label for="communications">Envío de comunicaciones</label>
                </div>

                <div class="mb-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>

                <Button label="Actualizar" icon="pi pi-check" class="w-full mt-4" @click="updateProfile" :disabled="canSend"></Button>

                <div class="mt-4" v-if="success"><small class="font-bold">Tu perfil se ha actualizado correctamente.</small></div>
            </div>
        </div>
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import AuthService from '@/service/AuthService';
import UserService from '@/service/UserService';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, email, minLength, maxLength, sameAs } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import CustomValidators from '@/customValidators';

const compUrl = '/my-profile';

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            name: '',
            email: '',
            dwelling: '',
            phone: '',
            communications: true,
            password: '',
            password2: '',
            working: false,
            updateError: null,
            success: false
        };
    },
    authService: null,
    userService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.userService = new UserService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;

        this.fetchProfile();
    },
    validations() {
        return {
            name: { required, minLength: minLength(2), maxLength: maxLength(255) },
            email: { required, email },
            dwelling: { required, minLength: minLength(2), maxLength: maxLength(255) },
            phone: { minLength: minLength(9), maxLength: maxLength(15) },
            password: { minLength: minLength(8), password: CustomValidators.password },
            password2: { requiredIfPassword: requiredIf(this.password), sameAs: sameAs(this.password) },
        };
    },
    emits: ['updateBreadcrumbs'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Mi perfil', to: compUrl, replace: true});
    },
    computed: {
        canSend() {
            return this.v$.name.$errors.length !== 0
                || this.v$.email.$errors.length !== 0
                || this.v$.dwelling.$errors.length !== 0
                || this.v$.phone.$errors.length !== 0
                || this.v$.password.$errors.length !== 0
                || this.v$.password2.$errors.length !== 0
                || this.name.length < 2
                || this.email.length === 0
                || (this.dwelling && this.dwelling.length < 2)
                || (this.phone && this.phone.length < 9)
                || (this.phone && this.phone.length > 15)
                || this.working;
        }
    },
    methods: {
        fetchProfile() {
            this.$appState.loading += 1;

            this.userService.fetchProfile()
                .then(response => {
                    this.$appState.loading -= 1;
                    this.name = response.data.user.name;
                    this.email = response.data.user.email;
                    this.dwelling = response.data.user.extra_data?.dwelling ?? '';
                    this.phone = response.data.user.phone;
                    this.communications = response.data.user.communications;
                })
                .catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.$appState.loading -= 1;
                    this.updateError = 'ERROR R-00: Ha ocurrido un error al obtener tu perfil';
                });
        },
        updateProfile() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;
                this.success = false;

                if (this.password && this.password.length > 0 && this.v$.password.$errors.length === 0 && this.v$.password2.$errors.length === 0) {
                    let w = new Worker('/pwhash-worker.js');
                    let salt;

                    this.authService.fetchUserNonce(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.email
                    ).then(response => {
                        if (response.data.salt && response.data.nonce) {
                            salt = window.sodium.from_hex(response.data.salt);
                            w.postMessage([salt, this.password]);
                        } else {
                            this.updateError = 'ERROR R-04: Ha ocurrido un error al actualizar tu perfil';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {email: this.email}),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR R-01: Ha ocurrido un error al actualizar tu perfil';
                    });

                    w.onmessage = (e) => {
                        let kp = window.sodium.crypto_sign_seed_keypair(window.sodium.from_hex(e.data));
                        let salt_signature = window.sodium.crypto_sign_detached(salt, kp.privateKey);

                        this.userService.updateProfile(
                            this.name,
                            this.email,
                            this.dwelling,
                            this.phone,
                            this.communications,
                            window.sodium.to_hex(salt),
                            window.sodium.to_hex(kp.publicKey),
                            window.sodium.to_hex(salt_signature)
                        ).then(response => {
                            this.working = false;

                            if (response.data.user) {
                                this.success = true;
                            } else {
                                this.updateError = 'ERROR R-03: Ha ocurrido un error al actualizar tu perfil';
                            }
                        }).catch(error => {
                            console.log(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request, {
                                    email: this.email,
                                    name: this.name,
                                    dwelling: this.dwelling,
                                    phone: this.phone,
                                    communications: this.communications,
                                }),
                                this.errorReportService.getErrorData(error.response),
                            );
                            this.working = false;
                            this.updateError = 'ERROR R-02: Ha ocurrido un error al actualizar tu perfil';
                        });
                    };
                } else {
                    this.userService.updateProfile(
                        this.name,
                        this.email,
                        this.dwelling,
                        this.phone,
                        this.communications,
                        null,
                        null,
                        null,
                    ).then(response => {
                        this.working = false;

                        if (response.data.user) {
                            this.success = true;
                        } else {
                            this.updateError = 'ERROR R-05: Ha ocurrido un error al actualizar tu perfil';
                        }
                    }).catch(error => {
                        console.log(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                email: this.email,
                                name: this.name,
                                dwelling: this.dwelling,
                                phone: this.phone,
                                communications: this.communications,
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.updateError = 'ERROR R-04: Ha ocurrido un error al actualizar tu perfil';
                    });
                }
            }
        },
        goLogin() {
            this.$router.replace({ name: 'login' });
        }
    }
}
</script>

<style scoped lang="scss">
</style>
